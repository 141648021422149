import Image from "next/image";
import Link from "next/link";
import { useState, useEffect } from "react";
import dynamic from "next/dynamic";

const VideoPlayer = dynamic(() => import("@/components/shared/VideoPlayer"), { ssr: false });
const Testimonials = dynamic(() => import("@/components/home/Testimonials"));

const Statistics = () => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const videoData = [
    {
      clip: "textClip.mp4",
      link: "https://crayo.ai/create/text-video?step=1",
      title: "Fake text video",
      subtitle: "Create text conversation shorts",
    },
    {
      clip: "clip2.mp4",
      link: "https://crayo.ai/create/story-video?step=1",
      title: "Reddit convo video",
      subtitle: "Create fictional Reddit-story shorts",
    },
    {
      clip: "clip1.mp4",
      link: "https://crayo.ai/create/split-video?step=1",
      title: "Split-screen video",
      subtitle: "Create split-screen shorts with gameplay",
    },
  ];

  return (
    <div className="flex flex-col items-center justify-between space-y-12 bg-gradient-to-b from-white to-[#F0F7FF] pt-28 text-center">
      <div className="-mb-8 flex w-full flex-col  px-8">
        <h3 className="text-[32px] md:text-[48px]">The software behind faceless virarlity</h3>
      </div>
      <div className="relative flex w-full justify-center">
        <div className="flex flex-col justify-center space-y-8 md:flex-row md:space-y-0 lg:flex-row lg:space-y-0 xl:flex-row">
          {videoData.map(({ clip, link, title, subtitle }, index) => (
            <Link
              href={link}
              key={index}
              className="duration-400 group mx-2 w-[260px] flex-shrink-0 space-y-2 rounded-3xl px-4 py-3 text-start transition-all hover:bg-crayoBlue/10"
            >
              {isClient && <VideoPlayer clip={clip} />}
              <div className="flex flex-col items-center space-y-2">
                <div className="flex w-full flex-row items-center space-x-2">
                  <h4 className="font-regular mt-2 text-xl">{title}</h4>
                  <Image
                    src="https://cdn-crayo.com/lp/public/rightArrow.png"
                    alt="arrow"
                    width={100}
                    height={100}
                    className="h-5 w-3 transform pt-2 transition-all duration-300 ease-in-out group-hover:translate-x-2"
                  />
                </div>
                <p className="pb-2 text-base font-medium leading-[120%] text-zinc-400">
                  {subtitle}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>

      <div className="px-0">
        <Testimonials index={1} />
      </div>
    </div>
  );
};

export default Statistics;
